import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import loadable from '@loadable/component'

import SearchGrid from '../components/search/grid/searchGrid'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const Footer = loadable(() => import('../components/footer/footer'))

const SearchResults = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Location>
      {({ location }) => (
        <Layout location={location} title={siteTitle} lang={'ar'}>
          <h1 className="customH1">{'المقالات الشائعة'}</h1>
          <SEO
            title="Trending articles"
            lang="ar"
            canonicalUrl={`${process.env.GATSBY_BASE_URL}trending/`}
          />

          <SearchGrid searchCategory="trending" />
          <div
            style={{
              margin: '-40px 0px 60px'
            }}
          >
            <Footer />
          </div>
        </Layout>
      )}
    </Location>
  )
}

export default SearchResults

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
